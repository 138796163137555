import Vue from "vue";

export const store = Vue.observable({
  isNavOpen: false,
  listsArticles: [],
  api: {
    access_token: process.env.API_TOKEN,
    base: process.env.API_URL
  },
});

export const mutations = {
  toggleNav() {
    store.isNavOpen = !store.isNavOpen;
  },
  clossNav() {
    store.isNavOpen = false;
  },
  setListsArticles(listsArticles) {
    store.listsArticles = listsArticles;
  },
};

export default store
