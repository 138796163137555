var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'buy')?_c('div',{staticClass:"recherche-buy"},[_c('buyer-recherche-module',{attrs:{"type":_vm.type}})],1):(_vm.type === 'sell')?_c('div',{staticClass:"recherche-sell"},[_c('sell-your-car-module',{attrs:{"type":_vm.type,"display-title":_vm.displayTitle,"hp-type":_vm.hpType}})],1):_c('div',{staticClass:"recherche-sell-buy"},[_c('ul',{staticClass:"nav nav-tabs nav-justified"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ active: _vm.isActive('buy') },attrs:{"href":"#buy"},on:{"click":function($event){$event.preventDefault();return _vm.setActive('buy')}}},[_c('img',{attrs:{"src":_vm.getPicto(
                _vm.isActive('buy') ? 'buy-active' : 'buy',
                'svg',
                'classique'
              ),"alt":'Acheter sa voiture'}}),_vm._v(" Acheter sa voiture ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ active: _vm.isActive('sell') },attrs:{"href":"#sell"},on:{"click":function($event){$event.preventDefault();return _vm.setActive('sell')}}},[_c('img',{attrs:{"src":_vm.getPicto(
                _vm.isActive('sell') ? 'sell-active' : 'sell',
                'svg',
                'classique'
              ),"alt":'Vendre sa voiture'}}),_vm._v(" Vendre sa voiture ")])])]),_c('div',{staticClass:"tab-content py-3",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade",class:{ 'active show': _vm.isActive('buy') },attrs:{"id":"buy"}},[_c('buyer-recherche-module')],1),_c('div',{staticClass:"tab-pane fade",class:{ 'active show': _vm.isActive('sell') },attrs:{"id":"sell"}},[_c('sell-your-car-module')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }