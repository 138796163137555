<template>
  <div class="makes-hp">
    <h2>Toutes nos marques</h2>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2 col-md-2 col-lg-2">
          <a
            v-for="word in makes[0]"
            :href="validateUrl('/' + word.toLowerCase() + '-occasion')"
            >{{ firstCharUpperCase(word) }}<br
          /></a>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2">
          <a
            v-for="word in makes[1]"
            :href="validateUrl('/' + word.toLowerCase() + '-occasion')"
            >{{ firstCharUpperCase(word) }}<br
          /></a>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2">
          <a
            v-for="word in makes[2]"
            :href="validateUrl('/' + word.toLowerCase() + '-occasion')"
            >{{ firstCharUpperCase(word) }}<br
          /></a>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2">
          <a
            v-for="word in makes[3]"
            :href="validateUrl('/' + word.toLowerCase() + '-occasion')"
            >{{ firstCharUpperCase(word) }}<br
          /></a>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2">
          <a
            v-for="word in makes[4]"
            :href="validateUrl('/' + word.toLowerCase() + '-occasion')"
            >{{ firstCharUpperCase(word) }}<br
          /></a>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2">
          <a
            v-for="word in makes[5]"
            :href="validateUrl('/' + word.toLowerCase() + '-occasion')"
            >{{ firstCharUpperCase(word) }}<br
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtilMixin from "../../../mixins/UtilMixin";
export default {
  _uaname: "makesListeHp",
  components: {},
  mixins: [UtilMixin],
  data() {
    return {
      displayed: false,
      makes: [
        [
          "ABARTH",
          "ALFA ROMEO",
          "ALPINE",
          "ASTON MARTIN",
          "AUDI",
          "BMW",
          "BMW i",
          "CHEVROLET",
        ],
        [
          "CHRYSLER",
          "CITROEN",
          "Corvette",
          "Cupra",
          "DACIA",
          "DAIHATSU",
          "DODGE",
          "DS",
        ],
        [
          "FIAT",
          "FORD",
          "HONDA",
          "HYUNDAI",
          "INFINITI",
          "JAGUAR",
          "JEEP",
          "KIA",
        ],
        [
          "LANCIA",
          "LAND ROVER",
          "LEXUS",
          "MASERATI",
          "MAZDA",
          "MERCEDES",
          "MINI",
          "MITSUBISHI",
        ],
        [
          "NISSAN",
          "OPEL",
          "PEUGEOT",
          "PORSCHE",
          "RENAULT",
          "SAAB",
          "SEAT",
          "SKODA",
        ],
        [
          "SMART",
          "SSANGYONG",
          "SUBARU",
          "SUZUKI",
          "TESLA",
          "TOYOTA",
          "VOLKSWAGEN",
          "VOLVO",
        ],
      ],
      partner: null,
      route: "",
      limit: 9,
    };
  },
  mounted() {
    this.route = this.getPartenaire().route;
    this.partner = this.getPartenaire().partner;
  },
  methods: {
    validateUrl(url) {
      return url + this.route;
    },
    firstCharUpperCase(word) {
      const string = word.toLowerCase();
      if (string === undefined || typeof string !== "string") return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/modules/makesModule";
</style>
