<template>
  <validation-provider
    ref="inputProvider"
    v-slot="{ errors, classes }"
    data-cy="cyInput"
    class="form-group form-label"
    :rules="rules"
    :mode="mode"
    :vid="vid"
  >
    <div class="row">
      <div class="col-12">
        <label
          class="field-label"
          :class="disabled === false ? 'activated' : 'disabled'"
          :for="id"
        >
          <span v-if="typeof trads === 'string'" class="label-text">
            {{ trads }}
          </span>
          <span v-else class="label-text">
            {{ trads.label }} <span>{{ trads.precision }}</span>
          </span>
        </label>
        <input
          v-if="numericalOnly"
          :id="id"
          v-model="innerValue"
          type="text"
          :name="name"
          inputmode="numeric"
          pattern="[0-9]*"
          class="input-element input-numerical"
          :class="
            Boolean(isValid)
              ? { ...classes, 'is-valid': !!isValid && !disabled }
              : showClasses(classes)
          "
          :placeholder="trads.placeholder || placeholder"
          :disabled="disabled"
          @blur="validateAndEmitValue"
          @focus="clearField"
          @keypress="preventNonNumericalInput"
          @input="passwordCheck"
        />
        <input
          v-else
          :id="id"
          v-model="innerValue"
          :type="type"
          :name="name"
          class="input-element input-default"
          :class="
            Boolean(isValid)
              ? { ...classes, 'is-valid': !!isValid && !disabled }
              : showClasses(classes)
          "
          :placeholder="trads.placeholder || placeholder"
          :disabled="disabled"
          @blur="validateAndEmitValue"
          @focus="clearField"
          @input="passwordCheck"
        />
        <b-popover
          :target="id"
          triggers="focus"
          :container="container"
          :placement="placement"
          :content="popover"
        ></b-popover>
        <span v-if="!isPassword" class="error-text-element">{{
          errors[0]
        }}</span>
        <!-- Password -->
        <div v-if="innerValue != ''" class="valid-password">
          <div v-if="isPassword">
            <p
              class="frmValidation"
              data-cy="inputPassword-length"
              :class="{ 'frmValidation--passed': innerValue.length >= 12 }"
            >
              <img
                v-if="innerValue.length >= 12"
                :src="getPicto('aloneValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              <img
                v-else
                :src="getPicto('noValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              {{ $t("modules.forms.passwordStrengthIndicator.hasLength") }}
            </p>
            <p
              class="frmValidation"
              data-cy="inputPassword-uppercase"
              :class="{ 'frmValidation--passed': has_uppercase }"
            >
              <img
                v-if="has_uppercase"
                :src="getPicto('aloneValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              <img
                v-else
                :src="getPicto('noValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              {{ $t("modules.forms.passwordStrengthIndicator.hasUppercase") }}
            </p>
            <p
              class="frmValidation"
              data-cy="inputPassword-lowercase"
              :class="{ 'frmValidation--passed': has_lowercase }"
            >
              <img
                v-if="has_lowercase"
                :src="getPicto('aloneValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              <img
                v-else
                :src="getPicto('noValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              {{ $t("modules.forms.passwordStrengthIndicator.hasLowercase") }}
            </p>
            <p
              class="frmValidation"
              data-cy="inputPassword-number"
              :class="{ 'frmValidation--passed': has_number }"
            >
              <img
                v-if="has_number"
                :src="getPicto('aloneValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              <img
                v-else
                :src="getPicto('noValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              {{ $t("modules.forms.passwordStrengthIndicator.hasNumber") }}
            </p>
            <p
              class="frmValidation"
              data-cy="inputPassword-special"
              :class="{ 'frmValidation--passed': has_special }"
            >
              <img
                v-if="has_special"
                :src="getPicto('aloneValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              <img
                v-else
                :src="getPicto('noValidate')"
                :alt="$t('modules.forms.alt.valid')"
              />
              {{ $t("modules.forms.passwordStrengthIndicator.hasSpecial") }}
            </p>
          </div>
        </div>
        <!-- /Password -->
        <div class="info-good">
          <img
            v-if="subLabel !== null"
            id="popover-dated"
            :src="getPicto('good')"
            :alt="$t('modules.forms.alt.valid')"
          />
          <span
            v-if="subLabel !== null"
            class="ind-label"
            v-text="subLabel"
          ></span>
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import UtilMixin from "../../../mixins/UtilMixin";
import { VALIDATION_RULES } from "../../../constants";
import formsUtils from "../../../utils/forms";

export default {
  name: "InputFormModule",
  components: { ValidationProvider },
  mixins: [UtilMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: [String, Number],
      default: "",
    },
    trads: {
      type: [Object, String],
      default: () => ({
        label: "",
        precision: "",
        placeholder: "",
      }),
    },
    rules: {
      type: Object,
      default: () => VALIDATION_RULES.inputText,
    },
    silentValidation: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "lazy",
    },
    vid: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String || null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean || null,
      default: null,
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    popover: {
      type: String,
      default: "",
    },
    container: {
      type: String,
      default: null,
    },
    placement: {
      type: String,
      default: "bottom",
    },
  },
  data: () => ({
    innerValue: "",
    preventNonNumericalInput: null,
    validateAndEmitField: null,
    has_number: false,
    has_lowercase: false,
    has_uppercase: false,
    has_special: false,
  }),
  computed: {
    numericalOnly: (_this) => _this.type === "number",
    id: (_this) => _this.name + "IdBlock",
    isPassword: (_this) => _this.name === "password",
  },
  watch: {
    async defaultValue(newVal, oldVal) {
      this.innerValue = newVal.toString();
      this.$refs.inputProvider.value = newVal.toString();

      if (this.innerValue) {
        await this.validateAndEmitValue();
        this.$refs.inputProvider.validate();
      }
    },
  },
  created() {
    this.validateAndEmitField = formsUtils.validateAndEmitField.bind(this);
    this.preventNonNumericalInput =
      formsUtils.preventNonNumericalInput.bind(this);
  },
  async mounted() {
    this.innerValue = this.defaultValue;
    this.$refs.inputProvider.value = this.defaultValue;

    if (this.innerValue) {
      await this.validateAndEmitValue();
      this.$refs.inputProvider.validate();
    }
  },
  methods: {
    emitCallback() {
      this.$emit("update-value", this.innerValue);
    },
    async validateAndEmitValue() {
      await this.validateAndEmitField(
        this.$refs.inputProvider,
        this.emitCallback
      );
    },
    clearField() {
      this.$refs.inputProvider.reset();
    },
    passwordCheck($event) {
      this.has_number = /\d/.test(this.innerValue);
      this.has_lowercase = /[a-z]/.test(this.innerValue);
      this.has_uppercase = /[A-Z]/.test(this.innerValue);
      this.has_special = /[!@#$%^?&*:)(+=._-]/.test(this.innerValue);
      this.$emit("input", $event);
    },
    showClasses(classes) {
      // Suppression du valid sur les champs optionnels
      if (classes.required === false && [null, ""].includes(this.innerValue)) {
        classes["is-valid"] = false;
        return classes;
      }
      return classes;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/modules/inputModule";
</style>
