import store from '../store/avis'

const LocalStorage = {

  methods: {
    saveData() {
      window.localStorage.setItem(store.localStorageKey, JSON.stringify(store.data))
    },
    removeData() {
      window.localStorage.removeItem(store.localStorageKey)
    }
  }

};
export default LocalStorage
