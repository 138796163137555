<template>
  <div>
    <div class="quotation_module" :class="hpType ? '' : 'funnel-seller'">
      <h2 v-if="displayTitle">
        Vendez au meilleur prix à un particulier, comme un pro !
      </h2>
      <div class="form-estimate">
        <div class="row">
          <div class="d-flex w-100">
            <input-licence-plate-module
              id="licencePlate"
              :name="'licencePlate'"
              data-cy="licencePlate"
              :type="'text'"
              class="select-kilometer"
              :trads="trads"
              @keyup-enter="goToHome"
              @update-value="setImmatValue"
              @blur="immatCheck"
            />
          </div>
        </div>
        <div class="row">
          <div v-if="errorMsg !== null" class="valid-password">
            <span>{{ errorMsg }}</span>
          </div>
        </div>
        <div class="btn-estimation">
          <a target="_blank" class="c-link" :href="goToHomePartner()" @click="checkErrorMsg" v-if="this.partner === 'macif'">
            Estimer ma voiture
          </a>
          <button class="c-link" @click="goToHome" v-else>
            Estimer ma voiture
          </button>
        </div>
        <div class="row">
          <p class="immat">
            Si vous n'avez pas votre plaque d'immatriculation,<br />
            <a :href="validateUrl('/vendre-ma-voiture/vehicule')"
               @click="eventTracking(
                      'vendre_voiture',
                      'clics_module',
                      'no_registration'
                    )"
              >rendez-vous ici</a
            >
          </p>
          <div class="col-sm-4 col-md-4 col-lg-4 validate">
            <img
              :src="getPicto('aloneValidate', 'svg', 'classique')"
              :alt="'Meilleur prix qu\'une reprise'"
            />
            <span>Meilleur prix qu'une reprise</span>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4 validate">
            <img
              :src="getPicto('aloneValidate', 'svg', 'classique')"
              :alt="'Transaction sécurisée'"
            />
            <span>Transaction sécurisée</span>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4 validate">
            <img
              :src="getPicto('aloneValidate', 'svg', 'classique')"
              :alt="'Vente rapide'"
            />
            <span>Vente rapide</span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 cgv">
            <a target="_blank" :href="validateUrl('/donnees-personnelles')">
              Informations sur vos données personnelles
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputLicencePlateModule from "../forms/InputLicencePlateModule";
import UtilMixin from "../../../mixins/UtilMixin";
export default {
  name: "SellQuoatationModule",
  components: {
    InputLicencePlateModule,
  },
  mixins: [UtilMixin],
  props: {
    type: {
      type: String,
      default: "",
    },
    displayTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    hpType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      errorMsg: null,
      trads: "EX : AA-001-ZZ",
      immatValue: "",
      check: false,
      isCovea: "",
      partner: null,
      route: "",
    };
  },
  head() {
    return {
      title: "",
      meta: [{ content: "no-content" }],
    };
  },
  mounted() {
    this.route = this.getPartenaire().route;
    this.partner = this.getPartenaire().subdomain;
  },
  methods: {
    validateUrl(url) {
      return url + this.route;
    },
    setImmatValue(immatValue) {
      this.immatValue = immatValue;
    },
    immatCheck() {
      this.check = /^[a-zA-Z]{2}-?\d{3}-?[a-zA-Z]{2}$/.test(this.immatValue);
    },
    goToHome() {
      // Make loading
      const immat = this.immatValue;
      // Get immat information
      this.check = /^[a-zA-Z]{2}-?\d{3}-?[a-zA-Z]{2}$/.test(this.immatValue);

      if (this.check) {
        if (immat === "")
          this.errorMsg = "L'immatriculation ne peut être vide";

        // Ok => push to vehicle page
        window.location.href = `/vendre-ma-voiture/vehicule/immat/${immat}${this.route}`;
      } else this.errorMsg = "Veuillez saisir votre plaque au format AA-001-ZZ";
    },
    goToHomePartner() {
      // Make loading
      const immat = this.immatValue;
      // Get immat information
      this.check = /^[a-zA-Z]{2}-?\d{3}-?[a-zA-Z]{2}$/.test(this.immatValue);

      if (this.check) {
        if (immat === "") return
        if (this.partner)
          return `/vendre-ma-voiture/vehicule/immat/${immat}${this.route}`;
        else
          return `/vendre-ma-voiture/vehicule/immat/${immat}`;
      }
    },
    checkErrorMsg() {
      this.check = /^[a-zA-Z]{2}-?\d{3}-?[a-zA-Z]{2}$/.test(this.immatValue);
      if (this.check) return
      this.errorMsg = "Veuillez saisir votre plaque au format AA-001-ZZ";
    }
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/modules/_sellQuotationModule.scss";
</style>
