export default {
  async validateAndEmitField(provider, emitCallback) {
    const providerValidate = this.silentValidation
      ? await provider.validateSilent()
      : await provider.validate();
    if (providerValidate.valid) {
      emitCallback();
    }
  },
  preventNonNumericalInput(e) {
    e = e || window.event;
    const charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  },
};
