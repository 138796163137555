export default {
  methods: {
    getPartenaire() {
      let phoneNumber
      let phoneCompactPrefix
      let title
      let isCovea = null
      let route = ""
      let subdomain = null

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.size) {
        if (urlParams.get('partenaire')) subdomain = urlParams.get('partenaire').toLowerCase()
        if (urlParams.get('info')) isCovea = urlParams.get('info')
        route = window.location.search
      }

      switch (subdomain) {
        case "macif":
          phoneNumber = "01 86 95 78 15";
          phoneCompactPrefix = "+33186957815";
          title = "Devis assurance Auto Macif";
          break;
        case "matmut":
          phoneNumber = "01 84 80 80 43";
          phoneCompactPrefix = "+33184808043";
          title = "Devis assurance Auto Matmut";
          break;
        case "gmf":
          phoneNumber = "01 84 80 97 64";
          phoneCompactPrefix = "+33184809764";
          title = "Devis assurance Auto GMF";
          break;
        case "mma":
          phoneNumber = "01 84 80 44 21";
          phoneCompactPrefix = "+33184804421";
          title = "Devis assurance Auto MMA";
          break;
        case "maaf":
          phoneNumber = "01 84 80 69 93";
          phoneCompactPrefix = "+33184806993";
          title = "Devis assurance Auto MAAF";
          break;
        case "assu2000":
          phoneNumber = "01 84 80 94 08";
          phoneCompactPrefix = "+33184809408";
          title = "Devis assurance Auto Assu 2000";
          break;
        default:
          subdomain = null;
          phoneNumber = "01 86 95 04 14";
          phoneCompactPrefix = "+33186950414";
          title = "Retour à l'accueil";
      }
      return { subdomain, phoneNumber, phoneCompactPrefix, title, route, isCovea };
    },
    getPicto(pictoName, ext, folder) {
      return require(`../assets/img/light/${folder}/${pictoName}.${ext}`);
    },
    getPictodark(pictoName, ext, folder) {
      return require(`../assets/img/dark/${folder}/${pictoName}.${ext}`);
    },
    getLogos(logoName, ext) {
      return require(`../assets/img/logos/${logoName}.${ext}`);
    },
    getUrl() {
      if (process.env.NODE_ENV === "production") return process.env.PROD;
      else return process.env.VALIDE;
    },
    disableBackButton() {
      // disable back button
      window.location.hash = "no-back";
      window.location.hash = "no-back-btn"; // again because google chrome don't insert first hash into history
      window.onhashchange = function () {
        window.location.hash = "no-back-btn";
      };
    },
    eventTracking(event, eventAction, eventLocation, make, model) {
      if (this.$gtm) {
        this.$gtm.push({
          event: event,
          eventAction: eventAction,
          eventLocation: eventLocation,
          car_brand : make,
          car_model : model,
        })
      }
    }
  },
};
