export const store = {
  data: [],
  token: null,
  accounts: '101584294302953258620',
  locations: '4509105564724091383',
  clientId: '278942024691-u06829upfolohtbfnveapqiuaks4rbqq.apps.googleusercontent.com',
  clientSecret: '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',
  tokenRefresh: '1//03Oily1WLL5n2CgYIARAAGAMSNwF-L9Ir-12P2wJwd5h8JcdNxFHlSiMrxqbUH1J-p2TmPdVulrPLNqg32wUVmqr-gZD9HAcsTsQ',
  localStorageKey: 'avis',
}

export const mutations = {
  setData(value) {
    store.data = value;
  },
  setToken(value) {
    store.token = value;
  },
};

export default store