<template>
  <div>
    <div class="row licence-plate">
      <div class="d-flex w-100">
        <input-licence-plate-module
          id="licencePlate"
          :name="'licencePlate'"
          data-cy="licencePlate"
          :type="'text'"
          class="select-kilometer"
          :trads="trads"
          :disabled="true"
          @keyup-enter="goToHome"
          @update-value="setImmatValue"
          @blur="immatCheck"
        />
      </div>
      <div class="row">
        <div v-if="errorMsg !== null" class="valid-password">
          <span>{{ errorMsg }}</span>
        </div>
      </div>
      <div class="btn-estimation">
        <button target="_blank" class="c-link" @click="goToHome">
          {{ title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputLicencePlateModule from "../forms/InputLicencePlateModule";
export default {
  name: "SellQuoatationModule",
  components: {
    InputLicencePlateModule,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      errorMsg: null,
      trads: "EX : AA-001-ZZ",
      immatValue: "",
      check: false,
      Hcovea: "",
      partner: null,
      route: ""
    };
  },
  head() {
    return {
      title: "",
      meta: [{ content: "no-content" }],
    };
  },
  mounted() {
    this.route = this.getPartenaire().route;
    this.partner = this.getPartenaire().partner;
  },
  methods: {
    validateUrl(url) {
      return url + this.route;
    },
    setImmatValue(immatValue) {
      this.immatValue = immatValue;
    },
    immatCheck() {
      this.check = /^[a-zA-Z]{2}-?\d{3}-?[a-zA-Z]{2}$/.test(this.immatValue);
    },
    goToHome() {
      // Make loading
      const immat = this.immatValue;
      // Get immat information
      this.check = /^[a-zA-Z]{2}-?\d{3}-?[a-zA-Z]{2}$/.test(this.immatValue);

      if (this.check) {
        try {
          if (immat === "")
            this.errorMsg = "L'immatriculation ne peut être vide";

          // Ok => push to vehicle page

          if (this.partner)
            window.location.href =
              "/vendre-ma-voiture/vehicule/immat/" + immat + this.route;
          else
            window.location.href = "/vendre-ma-voiture/vehicule/immat/" + immat;
        } catch (errors) {
          const error = errors.length > 0 ? errors[0] : errors;
          this.errorMsg = error.message;
        }
      } else this.errorMsg = "Veuillez saisir votre plaque au format AA-001-ZZ";
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/modules/_licencePlateModule.scss";
</style>
