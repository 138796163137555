<template>
  <div>
    <div>
      <a
        class="btn d-none d-xl-block btn-buyer-transparant btn-callback callBackAjaxModal"
        data-cy="btnCallback"
        @click="toggleModal"
      >
        Rappel gratuit
      </a>
      <a class="telephone display-mobile d-xl-none">
        <img
          src="../../../assets/img/light/classique/phone.svg"
          :alt="'phone'"
        />
      </a>
    </div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Rappel gratuit</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="toggleModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <validation-observer ref="callBackObserver">
              <div class="form-group-callback form-group">
                <div class="row">
                  <div class="col-md-4 col-12">
                    <img
                      src="../../../assets/img/light/classique/GestionHelp.svg"
                      :alt="'Validé'"
                      class="ico-call-back"
                    />
                  </div>
                  <div class="col-md-8 col-12">
                    <div
                      v-if="display.success !== null"
                      class="text-success modal-callback-success"
                    >
                      <p class="success-message">
                        <img
                          src="../../../assets/img/light/classique/aloneValidate.svg"
                          :alt="'Validé'"
                          width="30"
                          class="ico"
                        />
                        Votre demande a bien été prise en compte. <br />
                      </p>
                      <p class="success-message-inspector">
                        Un conseiller va vous contacter dans les plus brefs
                        délais !
                        <img
                          src="../../../assets/img/light/satisfaction/satisfied.svg"
                          :alt="'Validé'"
                          class="ico"
                        />
                      </p>
                    </div>
                    <div v-else class="row help-contact-block">
                      <div class="col-md-6 col-12">
                        <input-module
                          :type="'number'"
                          :name="'phone'"
                          :trads="tradPhone"
                          :rules="rules.inputPhone"
                          @update-value="setPhone"
                        />
                      </div>
                      <div class="col-md-6 col-12">
                        <p class="fake-label-text"></p>
                        <div class="btn-check-call text-center">
                          <button
                            type="button"
                            :next-loading="loading"
                            class="btn btn-secondary"
                            @click="callMeBack"
                          >
                            <span>Me rappeler</span>
                          </button>
                        </div>
                      </div>
                      <div class="col-12 api-msg">
                        <span v-if="display.error !== null" class="text-danger">
                          Oups, une erreur technique nous empêche de vous
                          contacter. Merci de nous appeler au 01 86 95 04
                          14.</span
                        >
                        <span
                          v-if="display.number !== null"
                          class="text-danger"
                        >
                          Format incorrect (ex : 0601020304)</span
                        >
                      </div>
                      <div class="col-md-12 col-12">
                        <p>
                          Vous pouvez également nous contacter au
                          <span class="titre-bold">{{ callNumber }}.</span>
                        </p>
                        <p class="hopening-hours">
                          <span class="titre-bold"
                            >Horraires d’ouverture :</span
                          >
                          du lundi au vendredi de 9h à 19h30 et le samedi de 9h
                          à 18h. Appel non surtaxé. Prix d’un appel local
                        </p>
                        <div class="col-sm-12 col-md-12 col-lg-12 cgv">
                          <a
                            target="_blank"
                            :href="validateUrl('/donnees-personnelles')"
                          >
                            Informations sur vos données personnelles
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import UtilMixin from "../../../mixins/UtilMixin";
import InputModule from "../../../components/modules/forms/InputModule";
import { VALIDATION_RULES } from "../../../constants";

export default {
  name: "CallbackModalModule",
  components: {
    InputModule,
    ValidationObserver,
  },
  mixins: [UtilMixin],
  data: () => ({
    rules: VALIDATION_RULES,
    route: "",
    subdomain: null,
    callNumber: null,
    phone: "",
    type: 1,
    active: false,
    show: false,
    tradPhone: {
      label: "Téléphone",
      placeholder: "Ex : 0601020304",
      subLabel: "Permet de vous envoyer l'estimation sur votre mobile",
    },
    display: {
      error: null,
      number: null,
      success: null,
    },
    btnDisabled: false,
    loading: false,
  }),
  mounted() {
    // Partnaire loading
    this.subdomain = this.getPartenaire().subdomain;
    this.partner = this.getPartenaire().subdomain;
    this.callNumber = this.getPartenaire().phoneNumber;
    this.route = this.getPartenaire().route;

  },
  methods: {
    setPhone(phone) {
      this.phone = phone;
    },
    setCode(type) {
      this.type = type;
    },
    toggleModal() {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
      setTimeout(() => (this.show = !this.show), 10);
    },
    validateUrl(url) {
      return url + this.route;
    },
    async callMeBack() {
      // Réinititialisation des éventuels messages et boutons
      // this.display = {
      //   error: null,
      //   number: null,
      //   success: null,
      // };
      //
      // if (this.phone.includes("08"))
      //   this.display.number = "Format incorrect (ex : 0601020304)";
      // else {
      //   this.btnDisabled = false;
      //   this.loading = true;
      //   try {
      //     const response = await this.$store.dispatch(
      //       "webCallBack/getAddWebCallBack",
      //       this.phone
      //     );
      //     if (response === "Appel en cours...") {
      //       this.display.success = "Votre demande a bien été prise en compte.";
      //       this.closeModal();
      //     } else {
      //       this.display.error =
      //         "Oups, une erreur technique nous empêche de vous contacter. Merci de nous appeler au 01 86 95 04 14.";
      //     }
      //   } catch (e) {
      //     this.display.error =
      //       "Oups, une erreur technique nous empêche de vous contacter. Merci de nous appeler au 01 86 95 04 14.";
      //   }
      // }
    },
    closeModal() {
      const _this = this;
      setTimeout(function () {
        _this.$refs["mail-modal"].hide();
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/modules/modalCallBack";
</style>
