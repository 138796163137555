export const PHONE_NUMBER = "01 86 95 04 14";
export const PHONE_NUMBER_MMA = "01 84 80 44 21";
export const PHONE_NUMBER_GMF = "01 84 80 97 64";
export const PHONE_NUMBER_MAAF = "01 84 80 69 93";
export const PHONE_NUMBER_MAIF = "01 86 76 11 34";
export const PHONE_NUMBER_MATMUT = "01 84 80 80 43";
export const PHONE_NUMBER_MACIF = "01 86 95 78 15";
export const PHONE_NUMBER_ASSU2000 = "01 84 80 94 08";
export const PHONE_COMPACT_PREFIX = "+33186950414";
export const PHONE_COMPACT_PREFIX_MMA = "+33184804421";
export const PHONE_COMPACT_PREFIX_GMF = "+33184809764";
export const PHONE_COMPACT_PREFIX_MAAF = "+33184806993";
export const PHONE_COMPACT_PREFIX_MAIF = "+33186761134";
export const PHONE_COMPACT_PREFIX_MATMUT = "+33184808043";
export const PHONE_COMPACT_PREFIX_MACIF = "+33186957815";
export const PHONE_COMPACT_PREFIX_ASSU2000 = "+33184809408";

export const VALIDATION_RULES = {
  default: { required: false },
  inputPhone: { required: true, phone: true },
  inputPhoneOptional: { required: false, phone: true },
};
export const DEFAULT_PROPERTIES_FORMS = {
  name: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  defaultValue: {
    type: String,
    default: "",
  },
  silentValidation: {
    type: Boolean,
    default: true,
  },
  mode: {
    type: String,
    default: "lazy",
  },
  rules: {
    type: Object,
    default: () => {
      return VALIDATION_RULES.default;
    },
  },
};
