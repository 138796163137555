<template>
  <validation-provider
    ref="inputProvider"
    v-slot="{ errors, classes }"
    data-cy="cyInput"
    class="form-group form-label"
    :rules="rules"
    :mode="mode"
    :vid="vid"
  >
    <div class="row">
      <div class="col-12">
        <label
          class="field-label"
          :class="disabled === false ? 'activated' : 'disabled'"
        >
          <span class="label-text"> Plaque d’immatriculation </span>
        </label>
        <div class="input-element-parent">
          <input
            :id="id"
            v-model="innerValue"
            v-mask="'AA-###-AA'"
            :type="type"
            :name="name"
            class="input-element input-default"
            :class="
              Boolean(isValid)
                ? { ...classes, 'is-valid': !!isValid && !disabled }
                : classes
            "
            :placeholder="trads"
            @keyup.enter="validateAndGoToHome"
            @blur="validateAndEmitValue"
            @focus="clearField"
          />
        </div>
        <span class="error-text-element">{{ errors[0] }}</span>
        <div class="info-good">
          <img
            v-if="subLabel !== null"
            :src="getPicto('good', 'svg', 'classique')"
            :alt="'valide'"
          />
          <span
            v-if="subLabel !== null"
            class="ind-label"
            v-text="subLabel"
          ></span>
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import VueTheMask from "vue-the-mask";
import Vue from "vue";
import { VALIDATION_RULES } from "../../../constants";
import formsUtils from "../../../utils/forms";
import UtilMixin from "../../../mixins/UtilMixin";
Vue.use(VueTheMask);

export default {
  name: "InputFormModule",
  components: { ValidationProvider },
  mixins: [UtilMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    trads: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => VALIDATION_RULES.inputText,
    },
    silentValidation: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "lazy",
    },
    vid: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String || null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean || null,
      default: null,
    },
  },
  data: () => ({
    innerValue: "",
    preventNonNumericalInput: null,
    validateAndEmitField: null,
  }),
  computed: {
    numericalOnly: (_this) => _this.type === "number",
    id: (_this) => _this.name + "IdBlock",
  },
  created() {
    this.validateAndEmitField = formsUtils.validateAndEmitField.bind(this);
    this.preventNonNumericalInput =
      formsUtils.preventNonNumericalInput.bind(this);
  },
  async mounted() {
    this.innerValue = this.defaultValue;
    this.$refs.inputProvider.value = this.defaultValue;

    if (this.innerValue) {
      await this.validateAndEmitValue();
      this.$refs.inputProvider.validate();
    }
  },
  methods: {
    emitCallback() {
      this.$emit("update-value", this.innerValue);
    },
    emitGoToHome() {
      this.$emit("update-value", this.innerValue);
      this.$emit("keyup-enter", this.innerValue);
    },
    async validateAndEmitValue() {
      await this.validateAndEmitField(
        this.$refs.inputProvider,
        this.emitCallback
      );
    },
    async validateAndGoToHome() {
      await this.validateAndEmitField(
        this.$refs.inputProvider,
        this.emitGoToHome
      );
    },
    clearField() {
      this.$refs.inputProvider.reset();
    },
    goNext() {
      if (this.innerValue > 6000) {
        this.validateAndEmitValue();
      }
    },
  },
};
</script>
