<template>
  <div>
    <div v-if="type === 'buy'" class="recherche-buy">
      <buyer-recherche-module :type="type" />
    </div>
    <div v-else-if="type === 'sell'" class="recherche-sell">
      <sell-your-car-module
        :type="type"
        :display-title="displayTitle"
        :hp-type="hpType"
      />
    </div>
    <div v-else class="recherche-sell-buy">
      <ul class="nav nav-tabs nav-justified">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: isActive('buy') }"
            href="#buy"
            @click.prevent="setActive('buy')"
          >
            <img
              :src="
                getPicto(
                  isActive('buy') ? 'buy-active' : 'buy',
                  'svg',
                  'classique'
                )
              "
              :alt="'Acheter sa voiture'"
            />
            Acheter sa voiture
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: isActive('sell') }"
            href="#sell"
            @click.prevent="setActive('sell')"
          >
            <img
              :src="
                getPicto(
                  isActive('sell') ? 'sell-active' : 'sell',
                  'svg',
                  'classique'
                )
              "
              :alt="'Vendre sa voiture'"
            />
            Vendre sa voiture
          </a>
        </li>
      </ul>
      <div id="myTabContent" class="tab-content py-3">
        <div
          id="buy"
          class="tab-pane fade"
          :class="{ 'active show': isActive('buy') }"
        >
          <buyer-recherche-module />
        </div>
        <div
          id="sell"
          class="tab-pane fade"
          :class="{ 'active show': isActive('sell') }"
        >
          <sell-your-car-module />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sellYourCarModule from "../../../components/modules/seller/SellQuotationModule";
import buyerRechercheModule from "../../../components/modules/buyer/buyerRechercheModule";
import UtilMixin from "../../../mixins/UtilMixin";
export default {
  _uaname: "ModuleDeRechercheHomePage",
  components: {
    buyerRechercheModule,
    sellYourCarModule,
  },
  mixins: [UtilMixin],
  props: {
    type: {
      type: String,
      default: "",
    },
    hpType: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => {
    return {
      activeItem: "buy",
    };
  },
  computed: {
    isValidClass() {
      return (isValidationObserverValid) => {
        return this.areListsAndDatesValid && isValidationObserverValid
          ? "is-valid"
          : "";
      };
    },
    vehicleObserver() {
      return "vehicleObserver" + this.currentStepMileageIndex;
    }
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      this.eventTracking(
          menuItem === 'sell' ? 'vendre_voiture' : 'acheter_voiture',
          'clics_module',
          'popin'
      )
    }
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/modules/_rechercheModule.scss";
</style>
