<template>
  <footer-module />
</template>

<script>
export default {
  name: "FooterPanel",
  components: {
    "footer-module": () =>
      import(
        /* webpackChunkName: "error-payment-module", webpackMode: "lazy" */ "../../../components/modules/layouts/FooterModule"
      ),
  },
  props: {
    organisation: {
      type: String,
      default: "",
    },
  },
};
</script>
