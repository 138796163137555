<template>
  <div class="feedback mb-md-5 mb-0">
    <div class="carousel">
      <p class="bloc-title" v-if="pageSize !== '1'">
        Découvrez nos avis clients
      </p>
      <div class="feedback-google">
        <div class="feedback-stars">
          <span class="me-2">
            <img
                :src="getPicto('Google-logo', 'svg', 'classique')"
                alt="logo Google"
                width="40"
            />
          </span>
          <span class="feedback-score me-2">{{ formatNumber(avis.averageRating) }}</span>
          <span v-for="index in 4" :key="index"
          ><img
              :src="getPicto('star-yellow', 'svg', 'classique')"
              class="etoiles"
              width="24"
              alt="stars"
          /></span>
          <span
          ><img
              :src="getPicto('star-gray', 'svg', 'classique')"
              class="stars-gray"
              width="24"
              alt="etoiles"
          /></span>
        </div>
      </div>
      <carousel
          class="story-carousel story-carousel--colors story-carousel--multiple"
          hide-arrows
          :hide-arrows-on-bound="false"
      >
        <slide
            v-for="(value, key) in avis.reviews"
            :key="key"
            :index="key"
            class="story-carousel__slide feedback-avis"
        >
          <b-row class="justify-content-between align-items-center">
            <b-col cols="10">
              <p class="feedback-name">{{ value.reviewer.displayName }}</p>
            </b-col>
            <b-col cols="2">
              <img
                  :src="getPicto('z', 'svg', 'classique')"
                  class="quote"
                  alt="image citation"
                  width="24"
              />
            </b-col>
          </b-row>
          <div class="feedback-stars mb-3">
              <span v-for="index in 5" :key="index">
                <img
                    :src="getPicto('star-yellow', 'svg', 'classique')"
                    alt="etoiles"
                    width="24"
                />
              </span>
          </div>
          <p class="feedback-content">{{ maxCaractere(value.comment) }}</p>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-snap";
import UtilMixin from "../../../mixins/UtilMixin";
export default {
  name: "GoogleAvisModule",
  components: {
    Carousel,
    Slide,
  },
  mixins: [UtilMixin],
  props: {
    pageSize: {
      type: String
    },
    avis: {
      type: [ Object, Array ],
      default: () => [],
    }
  },
  methods: {
    maxCaractere(string){
      var count = 140;
      return string.slice(0, count) + (string.length > count ? "..." : "");
    },
    formatNumber(number, suffix = "", decimals = 2){
      if (typeof number !== "number") return number;
      return (
          number.toLocaleString(undefined, { minimumFractionDigits: decimals }) +
          suffix
      );
    }
  },
};
</script>
<style lang="scss">
  @import "../../../assets/sass/modules/_googleAvisModule.scss";
  @import "../../../node_modules/vue-snap/dist/vue-snap.css";
</style>

