var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{ref:"inputProvider",staticClass:"form-group form-label",attrs:{"data-cy":"cyInput","rules":_vm.rules,"mode":_vm.mode,"vid":_vm.vid},scopedSlots:_vm._u([{key:"default",fn:function({ errors, classes }){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"field-label",class:_vm.disabled === false ? 'activated' : 'disabled'},[_c('span',{staticClass:"label-text"},[_vm._v(" Plaque d’immatriculation ")])]),_c('div',{staticClass:"input-element-parent"},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"},{name:"mask",rawName:"v-mask",value:('AA-###-AA'),expression:"'AA-###-AA'"}],staticClass:"input-element input-default",class:Boolean(_vm.isValid)
              ? { ...classes, 'is-valid': !!_vm.isValid && !_vm.disabled }
              : classes,attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.trads,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,null)>-1:(_vm.innerValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validateAndGoToHome.apply(null, arguments)},"blur":_vm.validateAndEmitValue,"focus":_vm.clearField,"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"},{name:"mask",rawName:"v-mask",value:('AA-###-AA'),expression:"'AA-###-AA'"}],staticClass:"input-element input-default",class:Boolean(_vm.isValid)
              ? { ...classes, 'is-valid': !!_vm.isValid && !_vm.disabled }
              : classes,attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.trads,"type":"radio"},domProps:{"checked":_vm._q(_vm.innerValue,null)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validateAndGoToHome.apply(null, arguments)},"blur":_vm.validateAndEmitValue,"focus":_vm.clearField,"change":function($event){_vm.innerValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"},{name:"mask",rawName:"v-mask",value:('AA-###-AA'),expression:"'AA-###-AA'"}],staticClass:"input-element input-default",class:Boolean(_vm.isValid)
              ? { ...classes, 'is-valid': !!_vm.isValid && !_vm.disabled }
              : classes,attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.trads,"type":_vm.type},domProps:{"value":(_vm.innerValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validateAndGoToHome.apply(null, arguments)},"blur":_vm.validateAndEmitValue,"focus":_vm.clearField,"input":function($event){if($event.target.composing)return;_vm.innerValue=$event.target.value}}})]),_c('span',{staticClass:"error-text-element"},[_vm._v(_vm._s(errors[0]))]),_c('div',{staticClass:"info-good"},[(_vm.subLabel !== null)?_c('img',{attrs:{"src":_vm.getPicto('good', 'svg', 'classique'),"alt":'valide'}}):_vm._e(),(_vm.subLabel !== null)?_c('span',{staticClass:"ind-label",domProps:{"textContent":_vm._s(_vm.subLabel)}}):_vm._e()])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }