<template>
  <google-avis-module :avis="avis" />
</template>

<script>
import googleAvisModule from "../../../components/modules/avis/googleAvisModule.vue"
export default {
  name: "GoogleAvisPanel",
  components: { googleAvisModule },
  props: {
    avis: {
      type: [ Object, Array ],
      default: () => [],
    }
  }
};
</script>

